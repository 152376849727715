import React from 'react';
import ReactDOM from 'react-dom';

import events from 'events.js';
import Menu from 'base/components/Menu.js';
import HomeSearch from 'search/components/HomeSearch.js';
import SearchForm from 'search/components/SearchForm.js';

window.called = false;

export async function setupMenu() {
    if (window.called) {
        return;
    }
    const menu = document.getElementById('menu');

    if (menu) {
        const props = {};
        const {
            source,
            interestId,
            interestName,
            specialtyId,
            specialtyName,
        } = document.body.dataset;

        const search = document.getElementById('search-root');
        const isReferralsLandingPage =
            (source === 'md' || source === 'bc') &&
            document.location.pathname === '/referrals/';
        props.isHomePage = document.location.pathname === '/';

        if (search && isReferralsLandingPage) {
            props.isReferralsLandingPage = isReferralsLandingPage;
            const landingProps = {
                isReferralsLandingPage,
                interest: {
                    id: interestId,
                    text: interestName,
                },
                specialty: {
                    id: specialtyId,
                    text: specialtyName,
                },
            };
            ReactDOM.render(<HomeSearch {...landingProps} />, search);
        }

        if (menu.dataset.network === 'true') {
            props.allowSignup = menu.dataset.networkAllowSignup === 'true';
            props.networkImg = menu.dataset.networkImg;
            props.networkReferralsUrl = menu.dataset.networkReferralsUrl;
            props.networkUrl = menu.dataset.networkUrl;
            props.registerUrl = menu.dataset.registerUrl;
            props.showFactsheets = menu.dataset.showFactsheets === 'true';
            props.showReferrals = menu.dataset.showReferrals === 'true';
        }
        if (menu.dataset.showMenuSearch === 'false') {
            props.showSearch = false;
        }
        if (menu.dataset.isReferralsPage === 'true') {
            props.isReferralsPage = true;
        }
        if (menu.dataset.client) {
            props.client = menu.dataset.client;
        }

        props.showAccountMenu = !menu.dataset.hideAccountMenu;
        ReactDOM.render(<Menu {...props} />, menu);
        window.called = true;
    } else {
        const {searchPlaceholder} = document.body.dataset;
        const skinMenu = document.querySelector('.skin-search');
        const props = {
            isClientReferralsPage: false,
            searchPlaceholder,
        };
        if (skinMenu) {
            ReactDOM.render(<SearchForm {...props} />, skinMenu);
            window.called = true;
        }
    }
}

(async function () {
    await events.ready();
    setupMenu();
})();
