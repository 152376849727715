import React from 'react';
import ReactDOM from 'react-dom';

import bsn from 'libraries/bootstrap-native.js';
import events from 'events.js';
import HomeSearch from 'search/components/HomeSearch.js';

(async function () {
    await events.ready();
    new bsn.Carousel(document.getElementById('homepage-carousel'), {
        interval: 10000,
    });

    const search = document.getElementById('search-root');
    if (search) {
        ReactDOM.render(<HomeSearch />, search);
    }
})();
